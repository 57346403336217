/*
 *  Document   : tools.js
 *  Author     : bememorable
 *  Description: Various small tools
 *
 */

// Import global dependencies
import './../bootstrap.js';

// Tools
export default class Tools {
    /*
     * Returns current browser's window width
     *
     */
    static getWidth() {
        return window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
    }
}